import React from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const StyledButton = styled.button`
  border-radius: 4px;
  border: none;
  padding: 10px;
  font-family: 'vcrMono';
  font-size: 18px;
  color: var(--primary-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }

  
  background-color: ${({ highContrast }) => (highContrast ? "var(--secondary-text)" : "var(--secondary)")};
  :hover {
    background-color: var(--secondary-text);
  }

  transition: background-color 1s;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 300px;
  @media (min-width: 900px) {
    width: 400px;
  }
  @media (min-width: 1000px) {
    width: 600px;
  }
  transition: width 0.5s;
`;

export const CentralImage = styled.img`
width: 100%;
 ${({ width }) => (width ? "max-width:" + width : "none")};
@media (min-width: 900px) {
  width: ${({ width }) => (width ? width : "900px")};
}
@media (min-width: 1000px) {
  width: ${({ width }) => (width ? width : "1000px")};
}
transition: width 0.5s;

${({ hideIfLessThan }) => (hideIfLessThan ? `@media (max-width: ${hideIfLessThan}) {
  display: none;
}` : "none")}
`;

export const ImageText = styled.h2`
width: 100%;
 ${({ width }) => (width ? "max-width:" + width : "none")};
@media (min-width: 900px) {
  width: ${({ width }) => (width ? width : "900px")};
}
@media (min-width: 1000px) {
  width: ${({ width }) => (width ? width : "1000px")};
}
transition: width 0.5s;
`;

export const SocialBlock = styled.div`
background-color: var(--accent);
display: flex;
flex: 1;
flex-direction: column;
align-items: center;
width: 100%;
`;

export const ImageButton = styled.button`
margin: ${({ margin }) => (margin ? margin : "5px")};
width: ${({ width }) => (width ? width : "248px")};
height: ${({ height }) => (height ? height : "96px")};
background-size: cover;
background-position: center;
border: none;

${({ hoverImage }) => (hoverImage != null ? `
background: url(${hoverImage});
opacity: 100%;

:hover {  
  opacity: 60%;
}
transition: opacity 0.3s;
;` : "")}

${({ image }) => (image != null ? `background: url(${image});` : "")}
`;

export const DiscordTag = styled.div`
width: 100%;
height: 200px;
background-color: var(--accent);
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
`;

export const ButtonGroup = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
width: 100%;
`

const Home = () => {
  const navigate = useNavigate();
  document.title = "Dragoverse NFT"
  return (
    <s.Screen>
      <s.Container
        id="home"
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--secondary)" }}
        image="/config/images/bg.png"
      >
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} test>
          <s.Container flex={2} ai={"center"}          >
            <s.TextP
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              Start your adventure in the
            </s.TextP>
            <StyledImg title="Dragoverse Project Logo" alt="Dragoverse Logo" src="/config/images/dragoverse-nft-project-logo.png" />
            <s.SpacerMedium />
            <s.TextH2
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              A collection of 5.000 unique Metagons Nfts
            </s.TextH2>
            <s.SpacerMedium />
            <s.SpacerMedium />

            {/* <StyledButton
              style={{
                margin: "5px",
                wordSpacing: "8px",
                height: "80px",
                width: "290px",
                fontSize: "28px",
                borderRadius: "10px"
              }}
              onClick={() => {
                // window.open('https://discord.gg/bvAQtyAacQ', '_blank');
                navigate('/mint');
              }}
            >
              Mint NOW
            </StyledButton> */}
            {/* <StyledButton
              style={{
                margin: "5px",
              }}
              onClick={() => {
                navigate('/mint');
              }}
            >
              Mint now
            </StyledButton> */}
            <s.SpacerMedium />
            <s.SpacerMedium />

            <CentralImage title="Metagons - Heroes of the Dragoverse" alt={"Metagons Nfts from the Dragoverse"} src={"/config/images/dragoverse-metagons-nft-drop.png"} />
            <SocialBlock>
              <s.SpacerMedium />

              <ButtonGroup>
                <ImageButton
                  hoverImage="/config/images/dragoverse-nft-project-discord-server.png"
                  onClick={() => {
                    window.open('https://discord.gg/bvAQtyAacQ', '_blank');
                  }}
                >
                </ImageButton>
                <ImageButton
                  hoverImage="/config/images/dragoverse-nft-project-twitter.png"
                  onClick={() => {
                    window.open('https://twitter.com/DragoverseDAO', '_blank');
                  }}
                >
                </ImageButton>
              </ButtonGroup>

              {/* <ButtonGroup>
                <ImageButton
                  hoverImage="/config/images/ig.png"
                  width="48px"
                  height="48px"
                  onClick={() => {
                    window.open('https://www.instagram.com/dragoverse_nft/', '_blank');
                  }}
                >
                </ImageButton>
                <ImageButton
                  hoverImage="/config/images/opensea.png"
                  width="48px"
                  height="48px"
                  onClick={() => {
                    window.open('https://opensea.io/collection/dragoverse', '_blank');
                  }}
                >
                </ImageButton>
                <ImageButton
                  hoverImage="/config/images/dragoverse-nft-contract-on-etherscan.png"
                  width="48px"
                  height="48px"
                  onClick={() => {
                    window.open('https://etherscan.io/address/0xf8e2075ae2fbaf1c4284ddae0b8955c5439860f6', '_blank');
                  }}
                >
                </ImageButton>
              </ButtonGroup> */}
              <s.SpacerMedium />
            </SocialBlock>
          </s.Container>
        </ResponsiveWrapper>

      </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--secondary)" }}
        image="/config/images/bg.png"
      >
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} test>
          <s.Container flex={2} ai={"center"}>
            <s.TextH1
              id="metagons"
              style={{
                textAlign: "center",
                fontSize: 50,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              What is a Metagon?
            </s.TextH1>
            <s.SpacerMedium />

            <ButtonGroup>

              <CentralImage width="576px" title="Animated Pixel Art Nfts - Dragoverse NFT" alt={"Nine animated Metagons frome the Dragoverse"} src={"/config/images/animated-nft-dragoverse-pixel-art.gif"} />

              <ImageText
                width="576px"
                style={{
                  padding: "32px",
                  textAlign: "start",
                  fontSize: 32,
                  color: "var(--accent-text)",
                  wordSpacing: "8px"
                }}
              >
                The Metagons are the creatures that inhabit the Dragoverse.
                Owning a Metagon is the beginning of an adventure with us and gives you access to Trainer Benefits and Features.
                Metagons are more than just NFTs. They're your unique pets that you can take with you on your adventures in Dragoverse!
              </ImageText>

            </ButtonGroup>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
      </s.Container>

      {/* <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--secondary)" }}
        image="/config/images/bg.png"
      >
        <ResponsiveWrapper flex={1} test>
          <s.Container flex={2} ai={"center"}>
            <s.TextH2
              id="roadmap"
              style={{
                textAlign: "center",
                fontSize: 50,
                color: "var(--accent-text)",
                wordSpacing: "10px"
              }}
            >
              Roadmap
            </s.TextH2>

            <s.TextP
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              Our Metagon scouts are searching far and wide..
            </s.TextP>

            <s.SpacerMedium />

            <ButtonGroup>
              <CentralImage width="576px" title="Dragoverse NFT Roadmap" alt={"Roadmap of the Dragoverse Project"} src={"/config/images/dragoverse-nfts-pixel-art-treasure-map-roadmap.png"} />
              <ImageText
                width="576px"
                style={{
                  padding: "32px",
                  textAlign: "start",
                  fontSize: 32,
                  color: "var(--accent-text)",
                  wordSpacing: "8px"
                }}
              >
                10% ➞ First Nfts Giveaway <br /><br />
                25% ➞ Second Nfts Giveaway and Promotion Campaign <br /><br />
                40% ➞ Development of the first game begins! <br /><br />
                50% ➞ Introduction of DRGV Token<br /><br />
                75% ➞ Introduction of staking <br /><br />
                100% ➞ Breeding, Evolutions and More! <br /><br />
              </ImageText>
            </ButtonGroup>

            <s.TextH4
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              Take part in our adventure, join us on Discord
            </s.TextH4>
            <StyledButton
              style={{
                margin: "5px",
                wordSpacing: "8px"
              }}
              onClick={() => {
                window.open('https://discord.gg/bvAQtyAacQ', '_blank');
              }}
            >
              Join
            </StyledButton>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />

      </s.Container> */}

      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--secondary)" }}
        image="/config/images/bg.png"
      >
        <ResponsiveWrapper flex={1} test>
          <s.Container flex={2} ai={"center"}>
            <s.TextP
              id="stats"
              style={{
                textAlign: "center",
                fontSize: 50,
                color: "var(--accent-text)",
                wordSpacing: "10px"
              }}
            >
              Obtain your Unique Metagon!
            </s.TextP>

            <s.TextH3
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              With different names, movesets, stats, rarities, descriptions and traits!
            </s.TextH3>

            <s.SpacerMedium />

            <ButtonGroup>
              <CentralImage width="576px" title="First Metagon's Pokemon like Stats" alt="First Metagon's Stats and Moves in a Pokemon like nft project" src={"/config/images/first-metagon-stats-moves-pokemon-like-rpg-nft.gif"} />
              {/* <CentralImage width="576px" title="Unknown Metagon's Pokemon like Stats" alt="Unknown Metagon's Stats and Moves in a Pokemon like nft project" hideIfLessThan="1152px" src={"/config/images/unknown-metagon-stats-moves-pokemon-like-rpg-nft.gif"} /> */}
            </ButtonGroup>

            <s.TextH4
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              Join us on Discord to get more sneak peeks!
            </s.TextH4>
            <StyledButton
              style={{
                margin: "5px",
              }}
              onClick={() => {
                window.open('https://discord.gg/bvAQtyAacQ', '_blank');
              }}
            >
              Join Now
            </StyledButton>

          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />

      </s.Container>

      {/* <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--secondary)" }}
        image="/config/images/bg.png"
      >
        <ResponsiveWrapper flex={1} test>
          <s.Container flex={2} ai={"center"}>
            <s.TextP
              id="team"
              style={{
                textAlign: "center",
                fontSize: 50,
                color: "var(--accent-text)",
                wordSpacing: "10px"
              }}
            >
              The Team
            </s.TextP>

            <s.TextH2
              style={{
                textAlign: "center",
                fontSize: 30,
                color: "var(--accent-text)",
                wordSpacing: "8px"
              }}
            >
              Dragoverse is brought to you by two brave Metagons:
            </s.TextH2>

            <ButtonGroup>
              <div>
                <s.SpacerSmall />
                <ImageText
                  width="400px"
                  style={{
                    textAlign: "center",
                    fontSize: 60,
                    color: "red",
                    wordSpacing: "8px"
                  }}
                >
                  Dronax
                </ImageText>
                <ImageText
                  width="400px"
                  style={{
                    textAlign: "center",
                    fontSize: 32,
                    color: "var(--accent-text)",
                    wordSpacing: "8px"
                  }}
                >
                  Founder / Artist
                </ImageText>
                <CentralImage width="400px" title="Dronax Metagon - The Artist's Metagon" alt={"The artist's Metagon, available somewhere in the collection"} src={"/config/images/artist-metagon-dragoverse-project-dronax.gif"} />

                <ImageText
                  width="400px"
                  style={{
                    padding: "0px 32px",
                    textAlign: "center",
                    fontSize: 32,
                    color: "var(--accent-text)",
                    wordSpacing: "8px"
                  }}
                >
                  Lead Artist and Founder of the Dragoverse Project
                </ImageText>
              </div>
              <s.SpacerSmall />

              <div>
                <s.SpacerSmall />
                <ImageText
                  width="400px"
                  style={{
                    textAlign: "center",
                    fontSize: 60,
                    color: "yellow",
                    wordSpacing: "8px"
                  }}
                >
                  DRGVDEv
                </ImageText>
                <ImageText
                  width="400px"
                  style={{
                    textAlign: "center",
                    fontSize: 32,
                    color: "var(--accent-text)",
                    wordSpacing: "8px"
                  }}
                >
                  Lead Developer
                </ImageText>
                <CentralImage width="400px" title="DRGVDEV Metagon - The Developer's Metagon" alt={"The Developer's Metagon, available somewhere in the collection"} src={"/config/images/developer-metagon-dragoverse-project-drgdev.gif"} />
                <ImageText
                  width="400px"
                  style={{
                    padding: "0px 32px",
                    textAlign: "center",
                    fontSize: 32,
                    color: "var(--accent-text)",
                    wordSpacing: "4px"
                  }}
                >
                  Creator of the website, he's working on the upcoming games
                </ImageText>
              </div>
            </ButtonGroup>

            <s.SpacerMedium />
            <s.SpacerLarge />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />

      </s.Container> */}

      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--secondary)" }}
        image="/config/images/bg.png"
      >
        <SocialBlock>
          <s.SpacerMedium />
          <s.TextP
            style={{
              textAlign: "center",
              fontSize: 50,
              color: "var(--accent-text)",
              wordSpacing: "10px"
            }}
          >
            Soon on
          </s.TextP>
          <ButtonGroup>
            <ImageButton
              hoverImage="/config/images/premint.png"
              width="300px"
              height="150px"
              margin="10px"
              onClick={() => {
                window.open('https://www.premint.xyz', '_blank');
              }}
            >
            </ImageButton>
            <ImageButton
              hoverImage="/config/images/mint-party.png"
              width="300px"
              height="150px"
              margin="10px"
              onClick={() => {
                window.open('https://about.mintparty.xyz/', '_blank');
              }}
            >
            </ImageButton>
            <ImageButton
              hoverImage="/config/images/nft-calendar.png"
              width="300px"
              height="150px"
              margin="10px"
              onClick={() => {
                window.open('https://nftcalendar.io/', '_blank');
              }}
            >
            </ImageButton>
          </ButtonGroup>
          {/* <ButtonGroup>
            <ImageButton
              hoverImage="/config/images/nft-upcoming.png"
              width="300px"
              height="150px"
              margin="10px"
              onClick={() => {
                window.open('https://upcomingnft.net/event/dragoverse-nft/', '_blank');
              }}
            >
            </ImageButton>
            <ImageButton
              hoverImage="/config/images/nft-reviews.png"
              width="300px"
              height="150px"
              margin="10px"
              onClick={() => {
                window.open('https://nonfungiblereviews.net/listing/dragoverse-nft-pre-mint/', '_blank');
              }}
            >
            </ImageButton>
            <ImageButton
              hoverImage="/config/images/nft-pilot.png"
              width="300px"
              height="150px"
              margin="10px"
              onClick={() => {
                window.open('https://www.nftpilot.io/nft-project/dragoverse-nft/', '_blank');
              }}
            >
            </ImageButton>
          </ButtonGroup> */}
          <s.SpacerLarge />
          <ButtonGroup>
            <ImageButton
              hoverImage="/config/images/dragoverse-nft-community-discord.png"
              width="48px"
              height="48px"
              onClick={() => {
                window.open('https://discord.gg/bvAQtyAacQ', '_blank');
              }}
            >
            </ImageButton>
            <ImageButton
              hoverImage="/config/images/dragoverse-nft-official-twitter.png"
              width="48px"
              height="48px"
              onClick={() => {
                window.open('https://twitter.com/DragoverseDAO', '_blank');
              }}
            >
            </ImageButton>

            {/* <ImageButton
              hoverImage="/config/images/ig.png"
              width="48px"
              height="48px"
              onClick={() => {
                window.open('https://www.instagram.com/dragoverse_nft/', '_blank');
              }}
            >
            </ImageButton>
            <ImageButton
              hoverImage="/config/images/opensea.png"
              width="48px"
              height="48px"
              onClick={() => {
                window.open('https://opensea.io/collection/dragoverse', '_blank');
              }}
            >
            </ImageButton>
            <ImageButton
              hoverImage="/config/images/dragoverse-nft-contract-on-etherscan.png"
              width="48px"
              height="48px"
              onClick={() => {
                window.open('https://etherscan.io/address/0xf8e2075ae2fbaf1c4284ddae0b8955c5439860f6', '_blank');
              }}
            >
            </ImageButton> */}
          </ButtonGroup>
          <s.SpacerMedium />
        </SocialBlock>
      </s.Container>
    </s.Screen>
  );
};

export default Home;