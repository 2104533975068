import React, { useState } from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import $ from 'jquery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const StyledButton = styled.button`
  border-radius: 4px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-family: 'vcrMono';
  font-size: 18px;
  color: var(--primary-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: var(--secondary-text);
  }
  transition: background-color 1s;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const CentralImage = styled.img`
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const Stats = styled.div`
  width: 100%;

  @media (min-width: 900px) {
    width: 400px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;

export const StatBar = styled.img`
height: 24px;
`;

export const StatRow = styled.div`
display: flex;
align-items: center;
justify-content: space-between;

@media (max-width: 500px) {
  align-items: start;
  flex-direction: column;
}
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  :hover {
    color: var(--secondary);
  }
  transition: color 0.5s;
`;

export const StyledInput = styled.input`
  border-radius: 4px;
  border: none;
  background-color: white;
  padding: 10px;
  font-family: 'vcrMono';
  font-size: 18px;
  text-align: center;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  transition: background-color 1s;
`;

export const Rarity = styled.h3`
  color: var(--primary-text);
  ${({ rarity }) => (rarity ? "color: var(--" + rarity + "-rarity)" : "none")};
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

export const EmptyContainer = styled(s.Container)`
display: none;
@media (min-width: 1000px) {
  display: flex;
}
`;

function RarityChecker() {
  const [metagon, setMetagon] = useState(undefined);
  const [searchError, setError] = useState();
  const [metagonId, setId] = useState();
  document.title = "DRGV - Rarity Checker"

  const loadNewContent = (id) => {
    $.ajax("https://api.opensea.io/api/v1/asset/0xf8e2075ae2fbaf1c4284ddae0b8955c5439860f6/" + id, {
      success: function (response) {
        let metagon = {};
        metagon = {
          "id": id,
          "imageUrl": response.image_preview_url,
          "name": response.name.split("#")[0],
          "owner": response.owner?.user?.username,
          "description": response.description.split("\n\n")[1],
          "rarity": response.traits.find(x => x.trait_type == "Rarity").value,
          "moves": response.description.split("\n\n\n")[1].replaceAll("*", "").substring(13),
          "level": response.traits.find(x => x.trait_type == "Level").value,
          "atk": response.traits.find(x => x.trait_type == "Attack").value / 10,
          "def": response.traits.find(x => x.trait_type == "Defense").value / 10,
          "hp": response.traits.find(x => x.trait_type == "Health Points").value / 10,
          "speed": response.traits.find(x => x.trait_type == "Speed").value / 10,
          "luck": response.traits.find(x => x.trait_type == "Luck").value / 10,
          "evasion": response.traits.find(x => x.trait_type == "Evasion").value / 10,
        };
        setMetagon(metagon);
        setError();
        window.scrollTo(0, 100);
      },
      error: function (response) {
        setMetagon(undefined);
        setError("Metagon not found.");
        window.scrollTo(0, 100);
      }
    });
  };

  const fireSearch = () => {
    loadNewContent(metagonId);
  };

  const setMetagonId = (id) => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    setId(value);
  };

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--secondary)", }}
        image="/config/images/bg.png"
      >
        <s.SpacerSmall />
        <ResponsiveWrapper flex={2} style={{ padding: 24 }} test>
          <EmptyContainer flex={1}>
          </EmptyContainer>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            // jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextH1
              style={{
                textAlign: "center",
                fontSize: 40,
                color: "var(--accent-text)",
                letterSpacing: "1px",
                wordSpacing: "8px"
              }}
            >
              OFFICIAL RARITY CHECKER
            </s.TextH1>
            <s.TextH2
              style={{
                textAlign: "center",
                fontSize: 24,
                color: "var(--accent-text)",
                letterSpacing: "1px",
                wordSpacing: "4px"
              }}
            >
              Instant Authenticity and Rarity Checker
            </s.TextH2>
            <s.SpacerSmall />
            <CentralImage
              title="Metagon Rarity Checker"
              alt={"Dragoverse NFT Rarity Checker"}
              src={metagon ? metagon.imageUrl : "/config/images/dragoverse-nft-rarity-checker.gif"}
            />
            <s.SpacerMedium />
            {searchError ?
              <s.TextH2
                style={{
                  textAlign: "center",
                  fontSize: 24,
                  color: "var(--accent-text)",
                  letterSpacing: "1px",
                  wordSpacing: "4px",
                  backgroundColor: "red",
                  borderRadius: "8px",
                  padding: "8px"
                }}
              >
                Error: Metagon not found. <br />This Metagon doesn't exist or isn't minted yet.
              </s.TextH2>
              : null}
            {metagon ? [
              <Stats
                flex={2}
                style={{
                  textAlign: "start",
                  fontSize: 32,
                  color: "var(--accent-text)",
                  letterSpacing: "1px",
                  wordSpacing: "4px"
                }}
              >
                <Rarity
                  rarity={metagon.rarity}
                  style={{
                    fontSize: 24,
                    letterSpacing: "1px",
                    wordSpacing: "4px"
                  }}
                >
                  {metagon.rarity}
                </Rarity>
                <span>Name: {metagon.name}</span><br />
                <s.TextH3
                  style={{
                    fontSize: 24,
                    color: "var(--accent-text)",
                    letterSpacing: "1px",
                    wordSpacing: "4px"
                  }}
                >
                  Owner: {metagon.owner ?? "unknown"}
                </s.TextH3>
                <br />
                <Accordion style={{
                  background: "none",
                  color: "white"
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      background: "none",
                    }}
                  >
                    Stats
                  </AccordionSummary>
                  <AccordionDetails style={{
                    padding: "0px",
                  }}>
                    <span>Level: {metagon.level}</span><br />
                    <StatRow>
                      <span>Hp:</span> <StatBar src={"/config/images/statbar" + metagon.hp + ".png"} />
                    </StatRow>
                    <StatRow>
                      <span>Attack:</span> <StatBar src={"/config/images/statbar" + metagon.atk + ".png"} />
                    </StatRow>
                    <StatRow>
                      <span>Defense:</span> <StatBar src={"/config/images/statbar" + metagon.def + ".png"} />
                    </StatRow>
                    <StatRow>
                      <span>Speed:</span> <StatBar src={"/config/images/statbar" + metagon.speed + ".png"} />
                    </StatRow>
                    <StatRow>
                      <span>Luck:</span> <StatBar src={"/config/images/statbar" + metagon.luck + ".png"} />
                    </StatRow>
                    <StatRow>
                      <span>Evasion:</span> <StatBar src={"/config/images/statbar" + metagon.evasion + ".png"} />
                    </StatRow>
                    <br />
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{
                  background: "none",
                  color: "white"
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      background: "none",
                    }}
                  >
                    Description
                  </AccordionSummary>
                  <AccordionDetails style={{
                    fontSize: "24px"
                  }}>
                    {metagon.description}
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{
                  background: "none",
                  color: "white"
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      background: "none",
                    }}
                  >
                    Moveset
                  </AccordionSummary>
                  <AccordionDetails style={{
                    fontSize: "24px",
                    whiteSpace: "pre-line"
                  }}>
                    {metagon.moves}
                  </AccordionDetails>
                </Accordion>
              </Stats>,
              <s.SpacerLarge />,
              <s.TextH4
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  color: "var(--accent-text)",
                  wordSpacing: "8px"
                }}
              >
                View on Opensea
              </s.TextH4>,
              <StyledButton
                style={{
                  margin: "5px",
                }}
                onClick={() => {
                  window.open('https://opensea.io/assets/0xf8e2075ae2fbaf1c4284ddae0b8955c5439860f6/' + metagon.id, '_blank');
                }}
              >
                Opensea
              </StyledButton>,
              <s.SpacerLarge />,
              <s.TextH2
                style={{
                  textAlign: "center",
                  fontSize: 24,
                  color: "var(--accent-text)",
                  letterSpacing: "1px",
                  wordSpacing: "4px"
                }}
              >
                Or <br />
                Search another Metagon
              </s.TextH2>
            ]
              :
              [
                <s.SpacerLarge />,
                <s.TextH2
                  style={{
                    textAlign: "center",
                    fontSize: 24,
                    color: "var(--accent-text)",
                    letterSpacing: "1px",
                    wordSpacing: "4px"
                  }}
                >
                  Search a Metagon.
                </s.TextH2>
              ]
            }

            <StyledInput type={"number"} min={0} max={9999} maxLength={4} onChange={setMetagonId}></StyledInput>
            <StyledButton
              style={{
                margin: "5px",
              }}
              onClick={fireSearch}
            >
              Search
            </StyledButton>
          </s.Container>
          <s.SpacerLarge />
          <EmptyContainer flex={1}>
          </EmptyContainer>
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}

export default RarityChecker;
