import Navbar from "react-bootstrap/Navbar";
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from "react-bootstrap/NavDropdown";
import styled from "styled-components";
import { HashLink } from 'react-router-hash-link';

export const NavbarLogo = styled.img`
height: 60px;
witdh: auto;
`;

export const StyledHashlink = styled(HashLink)`
text-decoration: none;
padding: 8px;
color: white;
opacity: 55%;

:hover {  
color: white;
opacity: 90%;
}

transition: opacity 0.3s;
`;

function CustomNavbar() {
  return <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark" href="/">
    <Container>
      <Navbar.Brand>
        <NavbarLogo alt={"logo"} src={"/config/images/dragoverse-nft-project-logo.png"} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <StyledHashlink to="/#home">Home</StyledHashlink>
          {/* <StyledHashlink to="mint">Mint</StyledHashlink> */}
          {/* <StyledHashlink to="/#roadmap">Roadmap</StyledHashlink> */}
          {/* <StyledHashlink to="rarity-checker">Rarity Checker</StyledHashlink> */}
          <NavDropdown menuVariant="dark" title="About &nbsp; Dragoverse" id="collasible-nav-dropdown">
            <NavDropdown.Item as={StyledHashlink} to="/#metagons">What's &nbsp; a &nbsp; Metagon?</NavDropdown.Item>
            <NavDropdown.Item as={StyledHashlink} to="/#stats">Stats &nbsp; and &nbsp; Movesets</NavDropdown.Item>
            {/* <NavDropdown.Item as={StyledHashlink} to="/#team">Team</NavDropdown.Item> */}
          </NavDropdown>
        </Nav>
        {/* <Nav>
          <Nav.Link onClick={() => {
            window.open('https://opensea.io/collection/dragoverse', '_blank');
          }}>Opensea</Nav.Link>
        </Nav> */}
      </Navbar.Collapse>
    </Container>
  </Navbar >
}

export default CustomNavbar;
