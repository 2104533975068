import { render } from "react-dom";
import { Redirect, Routes, Route, HashRouter, } from "react-router-dom";
import Mint from "./pages/Mint";
import Home from "./pages/Home";
import RarityChecker from "./pages/RarityChecker";
import CustomNavbar from "./CustomNavbar";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import ReactGA from 'react-ga';

const rootElement = document.getElementById("root");
const TRACKING_ID = "G-STR3FS05F4"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

render([
  <Provider store={store}>
    <HashRouter >
      <CustomNavbar></CustomNavbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/rarity-checker" element={<RarityChecker />} />
      </Routes>
    </HashRouter>
  </Provider>],
  rootElement
);